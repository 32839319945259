import ApiService from "./ApiService";

export default class frontService {
  static getCountryListing = () =>
    ApiService.get("/common/get-country-listing");

  static getPassportCountryListing = () =>
    ApiService.get("/common/get-passport-country-listing");

  static getCountriesList = () => ApiService.get("/common/get-countries-list");

  static customerApplicationForm = (data) =>
    ApiService.post("/front/customer-application-details", data);

  static customerApplicationPreview = (orderId) =>
    ApiService.post("/front/customer-application-preview", orderId);

  static deleteMember = (data) => ApiService.put("/front/delete-member", data);

  static getTotalAmount = (data) =>
    ApiService.post("/front/get-total-amount", data);

  static trackCustomerOrder = (orderData) =>
    ApiService.post(`/front/track-order`, orderData);

  static getDownloadHistory = (data) =>
    ApiService.post(`/front/get-download-history`, data);

  static downloadPassportDocPdf = (data) =>
    ApiService.post(`/front/download-passport-doc-pdf`, data);
}
