import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { getOrdersList } from "../../../redux/orderSlice";
import { StyledContainer, StyledOrderBlock, StyledPageTitle } from "./style";

const CustomerCalled = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CustomerCalledList = useSelector((state) => state?.order?.ordersList);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getOrdersList({
        orderName: "customerCalled",
        page: page,
        perPage: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    document.title = "Customer Called | USA Passport";
  }, [dispatch, navigate, page, limit]);

  const tableData = CustomerCalledList;
  const handleClick = () => {
    window.location.reload();
  };
  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Customer Called</h1>
        <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>

      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="customerCalled"
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default CustomerCalled;
