import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usepagination";
import "./pagination.scss";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    paginationRowsPerPageOptions,
    handleRowsChange,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  let lastPage = paginationRange[paginationRange?.length - 1];

  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
        key={"left"}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
            key={index}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
        key={"right"}
      >
        <div className="arrow right" />
      </li>
      <li className="pagination-item" key={"rows"}>
        <span>Rows:</span>
      </li>
      <li className="pagination-item" key={"select"}>
        <div>
          <select
            aria-label="Rows per page:"
            onChange={(e) => {
              handleRowsChange(e.target.value);
            }}
          >
            {paginationRowsPerPageOptions?.map((item, index) => {
              return (
                <option key={index} value={item} selected={pageSize === item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
      </li>
    </ul>
  );
};

export default Pagination;
