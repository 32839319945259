import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { StyleLoader } from './style';

const Loading = () => (
<StyleLoader>
    <FontAwesomeIcon icon={faSpinner} spin size="5x" className="loader" />
</StyleLoader>
);

export default Loading;
