import React from "react";
import { StyledFooter } from "./style";

const Footer = () => {
  return (
    <StyledFooter>
      <div
        className="container py-12"
        id="footer"
      >
        <div className="me-md-auto text-center">
          <div className="copyright">
          Copyright © 2023{" "}
            <strong>
              <span>vietnamportal.site</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
