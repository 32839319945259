import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../redux/orderSlice";
import { toast } from "react-toastify";

const PriorityOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const NewOrdersList = useSelector((state) => state?.order?.ordersList);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    if (token) {
      dispatch(
        getOrdersList({
          orderName: "priorityorder",
          page: page,
          perPage: limit,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.status === 401) {
            toast.error(`${res?.message}`, {
              className: "toast-message",
            });
            localStorage.removeItem("user");
            navigate("/");
          }
        });
    }
    document.title = "Priority Orders | Vietnam E-visa";
  }, [dispatch, navigate, page, limit, token]);

  const tableData = NewOrdersList;
  const handleClick = () => {
    window.location.reload();
  };
  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Priority Order</h1>
        <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>

      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="priorityorder"
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default PriorityOrders;
