import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
import rightArrow from "../../assests/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetailsByOrderId,
  resendMail,
  uploadVisaDocument,
} from "../../redux/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const isLoading = useSelector((state) => state?.order?.loading);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  const [emailId, setEmailId] = useState(OrderDetails?.email);
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
    }

    document.title = "Orders Details | Vietnam E-visa";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.email &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.email);
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email: OrderDetails?.email,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("orderId", values.orderId);
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      dispatch(uploadVisaDocument(data))
        .unwrap()
        .then((res) => {
          resetForm();
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(getOrderDetailsByOrderId(orderId));
          }
        });
    },
  });

  const handleResendMail = (e) => {
    e.preventDefault();
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
    };
    dispatch(resendMail(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "15%" }}>Order ID</th>
                      <th style={{ width: "35%" }}>Download</th>
                      <th style={{ width: "10%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          {OrderDetails?.uploadDoc?.length ? (
                            <td>
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH
                                    }docs/${item?.uploadDoc == null
                                      ? OrderDetails?.uploadDoc[0]?.file1
                                      : item?.uploadDoc.file1
                                    }`
                                    : null
                                }
                                className="blue-btn"
                                download
                                rel="noreferrer"
                              >
                                Download File{" "}
                              </a>
                            </td>
                          ) : (
                            ""
                          )}
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={handleResendMail}
                    >
                      Resend Mail{" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>PDF</td>
                      <tr>
                        <td>
                          <label className="file-upload">
                            <input
                              type="file"
                              className=""
                              id="fileUpload"
                              name="fileUpload"
                              accept=".pdf,image/*"
                              onChange={(e) =>
                                setFieldValue(
                                  "fileUpload",
                                  e.currentTarget.files[0]
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <span>Choose File</span>
                          </label>
                          <span className="slcted-file"></span>
                          <p>{errors.fileUpload}</p>
                        </td>
                      </tr>
                    </tr>
                    {OrderDetails?.uploadDoc?.length ? (
                      <td colSpan="2">
                        <a
                          href={
                            OrderDetails?.length !== 0
                              ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                              : null
                          }
                          className="blue-btn"
                          download
                          rel="noreferrer"
                        >
                          Download File{" "}
                        </a>
                      </td>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                >
                  Submit{" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Foreigner's Images</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.applicant_photo && (
                    <tr>
                      <td>Portrait Photography</td>
                      <td>
                        <a
                          href={`https://vietnamportal.online/uploads/${OrderDetails?.applicant_photo}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.applicant_passport_photo && (
                    <tr>
                      <td>Passport Data Page Image </td>
                      <td>
                        <a
                          href={`https://vietnamportal.online/uploads/${OrderDetails?.applicant_passport_photo}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Personal Information(PNR)</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.full_name && (
                    <tr>
                      <td>Full name (First name Middle name Last name)</td>
                      <td>{OrderDetails?.full_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.first_name && (
                    <tr>
                      <td>First Name </td>
                      <td>{OrderDetails?.first_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.last_name && (
                    <tr>
                      <td>Last Name </td>
                      <td>{OrderDetails?.last_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.date_of_birth && (
                    <tr>
                      <td>Date of birth</td>
                      <td>
                        {moment(OrderDetails?.date_of_birth).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.dob_type == "year" && (
                    <tr>
                      <td>DOB Type</td>
                      <td>
                        {OrderDetails?.dob_type == "year"
                          ? "Only in year"
                          : " Full date,month and year"}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.sex && (
                    <tr>
                      <td>Sex</td>
                      <td>{OrderDetails?.sex}</td>
                    </tr>
                  )}

                  {OrderDetails?.place_of_birth && (
                    <tr>
                      <td>Place Of Birth</td>
                      <td>{OrderDetails?.place_of_birth}</td>
                    </tr>
                  )}

                  {OrderDetails?.current_nationality && (
                    <tr>
                      <td>Current Nationality</td>
                      <td>{OrderDetails?.current_nationality}</td>
                    </tr>
                  )}

                  {OrderDetails?.religion && (
                    <tr>
                      <td>Religion</td>
                      <td>{OrderDetails?.religion}</td>
                    </tr>
                  )}

                  {OrderDetails?.id_card_number && (
                    <tr>
                      <td>ID Card Number</td>
                      <td>{OrderDetails?.id_card_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.email && (
                    <tr>
                      <td>Email</td>
                      <td>{OrderDetails?.email}</td>
                    </tr>
                  )}

                  {OrderDetails?.re_enter_email && (
                    <tr>
                      <td>Re Enter Email</td>
                      <td>{OrderDetails?.re_enter_email}</td>
                    </tr>
                  )}

                  {OrderDetails?.other_passport && (
                    <tr>
                      <td>
                        Have you ever used any other passports to enter into
                        Viet Nam?
                      </td>
                      <td>{OrderDetails?.other_passport}</td>
                    </tr>
                  )}


                  {OrderDetails?.customer_sign && (
                    <tr>
                      <td>Customer Signature</td>
                      <td>
                        <a
                          target="_blank"
                          href={`https://vietnamportal.online/${OrderDetails?.customer_sign}`}>
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.other_passport == "yes" && (
                    <>
                      {OrderDetails?.other_passport_number1 && (
                        <tr>
                          <td>Passport No 1</td>
                          <td>{OrderDetails?.other_passport_number1}</td>
                        </tr>
                      )}
                      {OrderDetails?.other_full_name1 && (
                        <tr>
                          <td>Full name 1</td>
                          <td>{OrderDetails?.other_full_name1}</td>
                        </tr>
                      )}
                      {OrderDetails?.other_date_of_birth1 && (
                        <tr>
                          <td>Date of birth 1</td>
                          <td>
                            {moment(OrderDetails?.other_date_of_birth1).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                      )}
                      {OrderDetails?.other_current_nationality1 && (
                        <tr>
                          <td>Nationality 1</td>
                          <td>{OrderDetails?.other_current_nationality1}</td>
                        </tr>
                      )}
                      {OrderDetails?.other_passport_number2 && (
                        <tr>
                          <td>Passport No 2</td>
                          <td>{OrderDetails?.other_passport_number2}</td>
                        </tr>
                      )}
                      {OrderDetails?.other_full_name2 && (
                        <tr>
                          <td>Full name 2</td>
                          <td>{OrderDetails?.other_full_name2}</td>
                        </tr>
                      )}
                      {OrderDetails?.other_date_of_birth2 && (
                        <tr>
                          <td>Date of birth 2</td>
                          <td>
                            {moment(OrderDetails?.other_date_of_birth2).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                      )}
                      {OrderDetails?.other_current_nationality2 && (
                        <tr>
                          <td>Nationality 2</td>
                          <td>{OrderDetails?.other_current_nationality2}</td>
                        </tr>
                      )}
                      {OrderDetails?.other_passport_number3 && (
                        <tr>
                          <td>Passport No 3 </td>
                          <td>{OrderDetails?.other_passport_number3}</td>
                        </tr>
                      )}
                      {OrderDetails?.other_full_name3 && (
                        <tr>
                          <td>Full name 3</td>
                          <td>{OrderDetails?.other_full_name3}</td>
                        </tr>
                      )}
                      {OrderDetails?.other_date_of_birth3 && (
                        <tr>
                          <td>Date of birth 3</td>
                          <td>
                            {moment(OrderDetails?.other_date_of_birth3).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                      )}
                      {OrderDetails?.other_current_nationality3 && (
                        <tr>
                          <td>Nationality 3</td>
                          <td>{OrderDetails?.other_current_nationality3}</td>
                        </tr>
                      )}
                    </>
                  )}

                  {OrderDetails?.multiple_nationalities && (
                    <tr>
                      <td>Do you have multiple nationalities?</td>
                      <td>{OrderDetails?.multiple_nationalities}</td>
                    </tr>
                  )}

                  {OrderDetails?.multiple_nationalities == "yes" && (
                    <tr>
                      <td>Multiple Nationalities Name</td>
                      <td>{OrderDetails?.multiple_nationality_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.violation_regulations && (
                    <tr>
                      <td>
                        Violation of the Vietnamese laws/regulations (if any)
                      </td>
                      <td>{OrderDetails?.violation_regulations}</td>
                    </tr>
                  )}

                  {OrderDetails?.violation_regulations == "yes" && (
                    <>
                      {OrderDetails?.act_of_violation1 && (
                        <tr>
                          <td>Act of violation 1</td>
                          <td>{OrderDetails?.act_of_violation1}</td>
                        </tr>
                      )}

                      {OrderDetails?.time_of_voilation1 && (
                        <tr>
                          <td>Time of violation 1</td>
                          <td>
                            {moment(OrderDetails?.time_of_voilation1).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                      )}

                      {OrderDetails?.form_of_sanction1 && (
                        <tr>
                          <td>Form of sanction 1</td>
                          <td>{OrderDetails?.form_of_sanction1}</td>
                        </tr>
                      )}

                      {OrderDetails?.authority_imposed_sanction1 && (
                        <tr>
                          <td>Authority imposed sanction 1</td>
                          <td>{OrderDetails?.authority_imposed_sanction1}</td>
                        </tr>
                      )}

                      {OrderDetails?.act_of_violation2 && (
                        <tr>
                          <td> Act of violation 2</td>
                          <td>{OrderDetails?.act_of_violation2}</td>
                        </tr>
                      )}

                      {OrderDetails?.violation_regulations == "yes" && (
                        <>
                          {OrderDetails?.time_of_voilation2 && (
                            <tr>
                              <td>Time of violation 2</td>
                              <td>
                                {moment(
                                  OrderDetails?.time_of_voilation2
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.form_of_sanction2 && (
                            <tr>
                              <td>Form of sanction 2</td>
                              <td>{OrderDetails?.form_of_sanction2}</td>
                            </tr>
                          )}

                          {OrderDetails?.authority_imposed_sanction2 && (
                            <tr>
                              <td>Authority imposed sanction 2</td>
                              <td>
                                {OrderDetails?.authority_imposed_sanction2}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.act_of_violation3 && (
                            <tr>
                              <td>Act of violation 3</td>
                              <td>{OrderDetails?.act_of_violation3}</td>
                            </tr>
                          )}

                          {OrderDetails?.time_of_voilation3 && (
                            <tr>
                              <td>Time of violation 3</td>
                              <td>
                                {moment(
                                  OrderDetails?.time_of_voilation3
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.form_of_sanction3 && (
                            <tr>
                              <td>Form of sanction 3</td>
                              <td>{OrderDetails?.form_of_sanction3}</td>
                            </tr>
                          )}

                          {OrderDetails?.authority_imposed_sanction3 && (
                            <tr>
                              <td>Authority imposed sanction 3</td>
                              <td>
                                {OrderDetails?.authority_imposed_sanction3}
                              </td>
                            </tr>
                          )}


                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2> Requested Information</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.evisa_entry && (
                    <tr>
                      <td>Entry Type </td>
                      <td>{OrderDetails?.evisa_entry}</td>
                    </tr>
                  )}

                  {OrderDetails?.visa_valid_from && (
                    <tr>
                      <td>Grant Evisa valid from</td>
                      <td>
                        {moment(OrderDetails?.visa_valid_from).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.visa_valid_to && (
                    <tr>
                      <td> Grant Evisa valid to</td>
                      <td>
                        {moment(OrderDetails?.visa_valid_to).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2> PASSPORT INRORMATION</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.passport_type && (
                    <tr>
                      <td>Type</td>
                      <td>{OrderDetails?.passport_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.other_passport_type_detail && (
                    <tr>
                      <td>Detail</td>
                      <td>{OrderDetails?.other_passport_type_detail}</td>
                    </tr>
                  )}

                  {OrderDetails?.passport_number && (
                    <tr>
                      <td>Passport number</td>
                      <td>{OrderDetails?.passport_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.place_of_issue && (
                    <tr>
                      <td>Issuing Authority/Place of issue</td>
                      <td>{OrderDetails?.place_of_issue}</td>
                    </tr>
                  )}

                  {OrderDetails?.issue_date && (
                    <tr>
                      <td> Date of issue</td>
                      <td>
                        {moment(OrderDetails?.issue_date).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.expiry_date && (
                    <tr>
                      <td>Expiry date</td>
                      <td>
                        {moment(OrderDetails?.expiry_date).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.other_passport_information && (
                    <tr>
                      <td> Do you hold any other valid passports? </td>
                      <td>{OrderDetails?.other_passport_information}</td>
                    </tr>
                  )}

                  {OrderDetails?.other_passport_information == "yes" && (
                    <>
                      {OrderDetails?.other_valid_passport_type && (
                        <tr>
                          <td>Type</td>
                          <td>{OrderDetails?.other_valid_passport_type}</td>
                        </tr>
                      )}

                      {OrderDetails?.other_valid_passport_number && (
                        <tr>
                          <td>Passport number</td>
                          <td>{OrderDetails?.other_valid_passport_number}</td>
                        </tr>
                      )}

                      {OrderDetails?.other_valid_place_of_issue && (
                        <tr>
                          <td>Issuing Authority/Place of issue</td>
                          <td>{OrderDetails?.other_valid_place_of_issue}</td>
                        </tr>
                      )}

                      {OrderDetails?.other_valid_issue_date && (
                        <tr>
                          <td> Date of issue</td>
                          <td>
                            {moment(
                              OrderDetails?.other_valid_issue_date
                            ).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                      )}

                      {OrderDetails?.other_valid_expiry_date && (
                        <tr>
                          <td>Expiry date</td>
                          <td>
                            {moment(
                              OrderDetails?.other_valid_expiry_date
                            ).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2> CONTACT INFORMATION</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.permanent_residential_address && (
                    <tr>
                      <td>Permanent Residential Address</td>
                      <td>{OrderDetails?.permanent_residential_address}</td>
                    </tr>
                  )}
                  {OrderDetails?.current_address && (
                    <tr>
                      <td>Current Address</td>
                      <td>{OrderDetails?.current_address}</td>
                    </tr>
                  )}
                  {OrderDetails?.emergency_full_name && (
                    <tr>
                      <td>Full name</td>
                      <td>{OrderDetails?.emergency_full_name}</td>
                    </tr>
                  )}
                  {OrderDetails?.emergency_current_residential_address && (
                    <tr>
                      <td>Current residential address</td>
                      <td>
                        {OrderDetails?.emergency_current_residential_address}
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.emergency_telephone_number && (
                    <tr>
                      <td>Telephone number</td>
                      <td>{OrderDetails?.emergency_telephone_number}</td>
                    </tr>
                  )}
                  {OrderDetails?.emergency_relationship && (
                    <tr>
                      <td>Relationship</td>
                      <td>{OrderDetails?.emergency_relationship}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>OCCUPATION</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.occupation && (
                    <tr>
                      <td>Occupation</td>
                      <td>{OrderDetails?.occupation}</td>
                    </tr>
                  )}

                  {OrderDetails?.occupation_name_of_company && (
                    <tr>
                      <td> Name of Company/Agency/School</td>
                      <td>{OrderDetails?.occupation_name_of_company}</td>
                    </tr>
                  )}

                  {OrderDetails?.occupation_position && (
                    <tr>
                      <td>Position/Course of study</td>
                      <td>{OrderDetails?.occupation_position}</td>
                    </tr>
                  )}

                  {OrderDetails?.occupation_address && (
                    <tr>
                      <td>Address</td>
                      <td>{OrderDetails?.occupation_address}</td>
                    </tr>
                  )}

                  {OrderDetails?.occupation_telephone_number && (
                    <tr>
                      <td>Telephone number</td>
                      <td>{OrderDetails?.occupation_telephone_number}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2> INFORMATION ABOUT THE TRIP</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.purpose_of_entry && (
                    <tr>
                      <td>Purpose of Entry</td>
                      <td>{OrderDetails?.purpose_of_entry}</td>
                    </tr>
                  )}

                  {OrderDetails?.name_of_hosting_organisation && (
                    <tr>
                      <td>Name of Hosting Organisation</td>
                      <td>{OrderDetails?.name_of_hosting_organisation}</td>
                    </tr>
                  )}
                  {OrderDetails?.hosting_organisation_address && (
                    <tr>
                      <td>Address</td>
                      <td>{OrderDetails?.hosting_organisation_address}</td>
                    </tr>
                  )}
                  {OrderDetails?.hosting_telephone_number && (
                    <tr>
                      <td>Telephone number</td>
                      <td>{OrderDetails?.hosting_telephone_number}</td>
                    </tr>
                  )}
                  {OrderDetails?.hosting_purpose && (
                    <tr>
                      <td>Purpose</td>
                      <td>{OrderDetails?.hosting_purpose}</td>
                    </tr>
                  )}
                  {OrderDetails?.intended_length && (
                    <tr>
                      <td>
                        Intended length of stay in Viet Nam (number of days)
                      </td>
                      <td>{OrderDetails?.intended_length}</td>
                    </tr>
                  )}
                  {OrderDetails?.intended_date_of_entry && (
                    <tr>
                      <td>Intended Date of Entry</td>
                      <td>
                        {moment(OrderDetails?.intended_date_of_entry).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.entry_through_checkpoint && (
                    <tr>
                      <td>Allowed to entry through checkpoint</td>
                      <td>{OrderDetails?.entry_through_checkpoint}</td>
                    </tr>
                  )}
                  {OrderDetails?.exit_through_checkpoint && (
                    <tr>
                      <td>Exit through checkpoint</td>
                      <td>{OrderDetails?.exit_through_checkpoint}</td>
                    </tr>
                  )}
                  {OrderDetails?.temporary_residential_address && (
                    <tr>
                      <td>
                        Intended temporary residential address in Viet Nam
                      </td>
                      <td>{OrderDetails?.temporary_residential_address}</td>
                    </tr>
                  )}

                  {OrderDetails?.city_province && (
                    <tr>
                      <td>City/Province </td>
                      <td>{OrderDetails?.city_province}</td>
                    </tr>
                  )}
                  {OrderDetails?.phone_number && (
                    <tr>
                      <td>Phone Number (in vietnam)</td>
                      <td>{OrderDetails?.phone_number}</td>
                    </tr>
                  )}
                  {OrderDetails?.vietnam_in_year && (
                    <tr>
                      <td>Have you been to Viet Nam in the last 01 year?</td>
                      <td>{OrderDetails?.vietnam_in_year}</td>
                    </tr>
                  )}
                  {OrderDetails?.vietnam_in_year == "yes" && (
                    <>
                      {OrderDetails?.vietnam_from1 && (
                        <tr>
                          <td>From 1</td>
                          <td>{OrderDetails?.vietnam_from1}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_to1 && (
                        <tr>
                          <td>To 1 </td>
                          <td>{OrderDetails?.vietnam_to1}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_purpose1 && (
                        <tr>
                          <td>Purpose 1 </td>
                          <td>{OrderDetails?.vietnam_purpose1}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_from2 && (
                        <tr>
                          <td>From 2</td>
                          <td>{OrderDetails?.vietnam_from2}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_to2 && (
                        <tr>
                          <td>To 2 </td>
                          <td>{OrderDetails?.vietnam_to2}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_purpose2 && (
                        <tr>
                          <td>Purpose 2 </td>
                          <td>{OrderDetails?.vietnam_purpose2}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_from3 && (
                        <tr>
                          <td>From 3</td>
                          <td>{OrderDetails?.vietnam_from3}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_to3 && (
                        <tr>
                          <td>To 3 </td>
                          <td>{OrderDetails?.vietnam_to3}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_purpose3 && (
                        <tr>
                          <td>Purpose 3 </td>
                          <td>{OrderDetails?.vietnam_purpose3}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_relative && (
                        <tr>
                          <td>
                            Do you have relatives who currently reside in Viet
                            Nam?
                          </td>
                          <td>{OrderDetails?.vietnam_relative}</td>
                        </tr>
                      )}

                      {OrderDetails?.vietnam_relative == "yes" && (
                        <>
                          {OrderDetails?.relative_full_name1 && (
                            <tr>
                              <td>Full name 1 </td>
                              <td>{OrderDetails?.relative_full_name1}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_dob1 && (
                            <tr>
                              <td>Date of birth 1</td>
                              <td>
                                {moment(OrderDetails?.relative_dob1).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.relative_nationality1 && (
                            <tr>
                              <td>Nationality 1</td>
                              <td>{OrderDetails?.relative_nationality1}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_relationship1 && (
                            <tr>
                              <td>Relationship 1</td>
                              <td>{OrderDetails?.relative_relationship1}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_residential_address1 && (
                            <tr>
                              <td>Residential address 1</td>
                              <td>
                                {OrderDetails?.relative_residential_address1}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.relative_full_name2 && (
                            <tr>
                              <td>Full name 2 </td>
                              <td>{OrderDetails?.relative_full_name2}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_dob2 && (
                            <tr>
                              <td>Date of birth 2</td>
                              <td>
                                {moment(OrderDetails?.relative_dob2).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.relative_nationality2 && (
                            <tr>
                              <td>Nationality 2</td>
                              <td>{OrderDetails?.relative_nationality2}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_relationship2 && (
                            <tr>
                              <td>Relationship 2</td>
                              <td>{OrderDetails?.relative_relationship2}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_residential_address2 && (
                            <tr>
                              <td>Residential address 2</td>
                              <td>
                                {OrderDetails?.relative_residential_address2}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.relative_full_name3 && (
                            <tr>
                              <td>Full name 3 </td>
                              <td>{OrderDetails?.relative_full_name3}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_dob3 && (
                            <tr>
                              <td>Date of birth 3</td>
                              <td>
                                {moment(OrderDetails?.relative_dob3).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.relative_nationality3 && (
                            <tr>
                              <td>Nationality 3</td>
                              <td>{OrderDetails?.relative_nationality3}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_relationship3 && (
                            <tr>
                              <td>Relationship 3</td>
                              <td>{OrderDetails?.relative_relationship3}</td>
                            </tr>
                          )}

                          {OrderDetails?.relative_residential_address3 && (
                            <tr>
                              <td>Residential address 3</td>
                              <td>
                                {OrderDetails?.relative_residential_address3}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Inviting/ Guarentering Agency/ Organization Information</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.name_of_hosting_organisation && (
                    <tr>
                      <td>Name of Hosting Organisation</td>
                      <td>{OrderDetails?.name_of_hosting_organisation}</td>
                    </tr>
                  )}

                  {OrderDetails?.hosting_organisation_address && (
                    <tr>
                      <td>Address</td>
                      <td>{OrderDetails?.hosting_organisation_address}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>
              Under 14 years old accompanying child(ren) information included in
              your passport
            </h2>
            <StyledOrderBlock>
              <h2>Children1 Information</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    {OrderDetails?.under_14_name_1 && (
                      <tr>
                        <td>Name</td>
                        <td>{OrderDetails?.under_14_name_1}</td>
                      </tr>
                    )}

                    {OrderDetails?.under_14_sex_1 && (
                      <tr>
                        <td>Sex</td>
                        <td>{OrderDetails?.under_14_sex_1}</td>
                      </tr>
                    )}

                    {OrderDetails?.under_14_dob_1 && (
                      <tr>
                        <td>Date of Birth</td>
                        <td>
                          {moment(OrderDetails?.under_14_dob_1).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                      </tr>
                    )}

                    {OrderDetails?.under_14_photo_1 && (
                      <tr>
                        <td>Photo</td>
                        <td>
                          <a
                            href={`https://vietnamportal.online/uploads/${OrderDetails?.under_14_photo_1}`}
                            target="_blank"
                            // download
                            rel="noreferrer"
                          >
                            Click here
                          </a>
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>

            <StyledOrderBlock>
              <h2>Children2 Information</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    {OrderDetails?.under_14_name_2 && (
                      <tr>
                        <td>Name</td>
                        <td>{OrderDetails?.under_14_name_2}</td>
                      </tr>
                    )}

                    {OrderDetails?.under_14_sex_2 && (
                      <tr>
                        <td>Sex</td>
                        <td>{OrderDetails?.under_14_sex_2}</td>
                      </tr>
                    )}

                    {OrderDetails?.under_14_dob_2 && (
                      <tr>
                        <td>Date of Birth</td>
                        <td>
                          {moment(OrderDetails?.under_14_dob_2).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                      </tr>
                    )}

                    {OrderDetails?.under_14_photo_2 && (
                      <tr>
                        <td>Photo</td>
                        <td>
                          <a
                            href={`https://vietnamportal.online/uploads/${OrderDetails?.under_14_photo_2}`}
                            target="_blank"
                            // download
                            rel="noreferrer"
                          >
                            Click here
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>TRIP’S EXPENSES, INSURANCE</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.intended_expenses && (
                    <tr>
                      <td>Intended expenses (in USD)</td>
                      <td>{OrderDetails?.intended_expenses}</td>
                    </tr>
                  )}

                  {OrderDetails?.agency && (
                    <tr>
                      <td>
                        Who will cover the trip’s expenses of the applicant
                      </td>
                      <td>
                        {OrderDetails?.agency == "CN"
                          ? "The applicant"
                          : "Other agency/organization/individual"}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.insurance && (
                    <tr>
                      <td>
                        Does the applicant have health insurance arranged for
                        their stay in Viet Nam?
                      </td>
                      <td>{OrderDetails?.insurance}</td>
                    </tr>
                  )}

                  {OrderDetails?.insurance_detail && (
                    <tr>
                      <td>Detail</td>
                      <td>{OrderDetails?.insurance_detail}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Download History</h2>
            <div className="table-block table-responsive">
              <table width="100%">
                <thead>
                  <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order ID</th>
                    <th width="22%">Date & Time</th>
                    <th width="23%">Downloaded File</th>
                    <th width="20%">IP Address</th>
                    <th width="20%">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderDetails?.downloadHistory ? (
                    OrderDetails?.downloadHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}{" "}
                            EST
                          </td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                download
                                rel="noreferrer"
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>{item?.ip}</td>
                          <td>{item?.timezone}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <form className="form-inline">
                <div className="form-group">
                  <label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control input-class"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    id="download_btn"
                    onClick={handleResendMail}
                    disabled={isLoading}
                  >
                    Sent Mail{" "}
                    <span>
                      <img src={rightArrow} alt="rightArrow" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </StyledOrderBlock>
          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" &&
                    OrderDetails.remark ? (
                    OrderDetails.remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.us_date).format("DD-MM-YYYY")}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                              selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type="button"
            className="blue-btn"
            data-toggle="modal"
            data-target="#refundModal"
            onClick={() => setShow(true)}
          >
            View Email Content{" "}
          </button>

          <MyModal
            show={show}
            close={setShow}
            size={"lg"}
            modalHeadTitle={"Email Content"}
            showFooter={true}
            primaryButtonName={"Close"}
            secondaryButtonName={"Send Whatsapp Message"}
            OrderDetails={OrderDetails}
          >
            <EmailContent />
          </MyModal>
        </StyledBottomBlock>
      </div>
    </>
  );
};

export default ViewOrders;
