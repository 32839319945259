import { configureStore } from "@reduxjs/toolkit";
import frontReducer from "./frontSlice";
import authReducer from "./authSlice";
import orderReducer from "./orderSlice";
import gateWayReducer from "./gateWaySlice";
import manageTeamReducer from "./manageTeamSlice";
import remarkReducer from "./remarkSlice";
import paymentReducer from "./paymentSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    front: frontReducer,
    order: orderReducer,
    manageTeam: manageTeamReducer,
    gateway: gateWayReducer,
    remark: remarkReducer,
    payment: paymentReducer,
  },
});
