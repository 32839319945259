import styled from 'styled-components';

const StyleLoader = styled.div`
  height: 414px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { StyleLoader };
