import React from "react";
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
// import { encryptVal } from "../../../utility/utility";
import AdminLogo from "../../../assests/img/vietnamLogo.jpg";

const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p>
        <b>{`Dear Customer: ${OrderDetails?.full_name ? OrderDetails?.full_name : `${ OrderDetails?.first_name} ${OrderDetails?.last_name}`}`}</b>
      </p>
      <p>Thank you for submitting your Vietnam E-VISA application.</p>
      <p>
        We have received your Vietnam E-VISA Application which will now be
        processed. Within 72 to 96 hours you will receive a link to our portal
        which will enable you to download your E-VISA. If you have not received
        anything after 96 hours please check your spam/junk folder.{" "}
      </p>
      <p>
        Should you have any inquiries, please contact us through our 
        <Link to="https://vietnamportal.online/contact.php">contact form </Link>
        or via email at 
        <a href="mailto:inquiries@vietnamportal.online">
          inquiries@vietnamportal.online 
        </a>
        indicating your order ID.
      </p>

      <p className="flex-class">
        <b>Customer Service Number:</b> +1 (407) 955 - 9631
      </p>
      <p className="flex-class">
        <b>Order ID:</b> {OrderDetails?.order_id}
      </p>

      <p>
        This transaction will appear as Vietnamportal.online on your bank
        account statement.
      </p>
      <br />

      <Link to="https://vietnamportal.online/apply.php" className="apply-btn">
        Submit Another E-visa Application
      </Link>
      <br />
      <br />

      <p>
        <b>Important Notice:</b>
      </p>
      <p>
        {" "}
        If you are not satisfied with the service you have received or wish to
        file a complaint, please contact us at 
        <a href="mailto:inquiries@vietnamportal.online">
          inquiries@vietnamportal.online{" "}
        </a>{" "}
        , where we will be happy to assist you or alternatively click on one of
        the links below for more information.
      </p>
      <p>
        You can access the terms and conditions you agreed to by clicking here
        <Link to="https://vietnamportal.online/terms.php"> click here</Link>
      </p>
      <p> Regards,</p>
      <p>Processing Department</p>
      <p>
        <Link to="https://vietnamportal.online/terms.php">Terms</Link> |{" "}
        <Link to="https://vietnamportal.online/privacy.php">Privacy</Link> |{" "}
        <Link to="https://vietnamportal.online/contact.php">Contact</Link> |{" "}
        <Link to="https://vietnamportal.online/refund.php">Refund</Link>
      </p>
    </EmailContainer>
  );
};

export default EmailContent;
