import styled from "styled-components";

export const StyledDashboard = styled.div`
  padding-left: 300px;
  padding-top: 108px;

  @media only screen and (max-width: 1175px) {
    padding: 0;
    padding-top: 120px;
  }

  @media only screen and (max-width: 991px) {
    padding: 0;
    // padding-top: 120px;
  }
`;

export const StyledMain = styled.div`
  padding: 10px;

  @media only screen and (max-width: 767px) {
    padding: 15px;
  }
`;
