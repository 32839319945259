import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import paymentService from "../services/paymentServices";

export const customerApplicationPreviewSubmit = createAsyncThunk(
  "/customerApplicationPreviewSubmit",
  async (previewData) => {
    try {
      const response = await paymentService.customerApplicationPreviewSubmit(
        previewData
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const processAuthorizeNetPay = createAsyncThunk(
  "/processAuthorizeNetPay",
  async (paymentData) => {
    try {
      const response = await paymentService.processAuthorizeNetPay(paymentData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const thankyouPageInformation = createAsyncThunk(
  "/thankyouPageInformation",
  async (thankyouData) => {
    try {
      const response = await paymentService.thankyouPageInformation(thankyouData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const sendContactMail = createAsyncThunk(
  "/sendContactMail",
  async (contactData) => {
    try {
      const response = await paymentService.sendContactMail(contactData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const applyNowReducer = createSlice({
  name: "applyNow",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(customerApplicationPreviewSubmit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customerApplicationPreviewSubmit.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      customerApplicationPreviewSubmit.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(processAuthorizeNetPay.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(processAuthorizeNetPay.fulfilled, (state, action) => {
      state.loading = false;
      //   state.applyNowFormData = action?.payload?.data;
      //   state.orderIDs = action?.payload?.orderId;
    });
    builder.addCase(processAuthorizeNetPay.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(thankyouPageInformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(thankyouPageInformation.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(thankyouPageInformation.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendContactMail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendContactMail.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendContactMail.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default applyNowReducer.reducer;
