import styled from "styled-components";

export const StyledDownload = styled.div`
  margin-bottom: 407px;
  padding-top: 200px;

  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.3);
    outline: 0;
    box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  }

  @media (max-width: 1366px) {
    margin-bottom: 287px;
  }
  @media (max-width: 991px) {
    margin-bottom: 297px;
  }
  @media (max-width: 767px) {
    margin-bottom: 240px;
  }

  @media (max-width: 560px) {
    margin-bottom: 200px;
  }


  & p{
    font-size:18px;
  }

  .btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
    border: none;
  }
  .btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
  .btn&:hover {
    color: var(--bs-btn-hover-color);
    background-color: #68737d !important;
    border-color: #68737d;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
  }

  .Responsive-table{
    margin-yop: 20px;
    overflow-x:auto;
  }

`;

export const StyledError = styled.p`
  color: red;
`;
