import Header from './Header/Header';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import Main from './Main';

const AdminTrackLayout = (props) => (
  <div>
    <Header />
    <Navbar />
    <Main {...props} />
    <Footer />
  </div>
);

export default AdminTrackLayout;


