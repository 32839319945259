import styled from "styled-components";

export const EmailContainer = styled.div`
  padding: 10px;
  margin: 5px;
  border: solid 2px #000;

  & hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 2px solid black;
    opacity: unset;
  }
  & a {
    text-decoration: none;
    color: #328dfa;
  }

  .apply-btn {
    color: #fff;
    background: #328dfa;
    border: none;
    padding: 10px;
    margin-bottom: 20px;
  }

  .flex-class {
    display: flex;
  }

  & img,
  svg {
    vertical-align: middle;
    width: 10rem;
  }

  hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 2px solid black;
    /* opacity: .25; */
  }

  .click-btn {
    background: #007bff;
    border: solid 2px #007bff;
    color: #fff;
    padding: 10px 25px;
  }
`;

export const StyleLogo = styled.div`
  padding-bottom: 10px;
  background-color: #6c8793;
  border-bottom: solid 1px #ccc;
  padding-top: 10px;
  padding-left: 10px;
`;
