import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import frontService from "../services/frontServices";

export const getCountryListing = createAsyncThunk(
  "/getCountryListing",
  async () => {
    try {
      const response = await frontService.getCountryListing();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPassportCountryListing = createAsyncThunk(
  "/getPassportCountryListing",
  async () => {
    try {
      const response = await frontService.getPassportCountryListing();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getCountriesList = createAsyncThunk(
  "/getCountriesList",
  async () => {
    try {
      const response = await frontService.getCountriesList();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// export const getCountryPhoneCodeListing = createAsyncThunk(
//   "/getCountryPhoneCodeListing",
//   async () => {
//     try {
//       const response = await applyNowService.getCountryPhoneCodeListing();
//       const data = await response.data;
//       return data;
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );

export const customerApplicationForm = createAsyncThunk(
  "/customerApplicationForm",
  async (applyData) => {
    try {
      const response = await frontService.customerApplicationForm(applyData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const customerApplicationPreview = createAsyncThunk(
  "/customerApplicationPreview",
  async (orderId) => {
    try {
      const response = await frontService.customerApplicationPreview(orderId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteMember = createAsyncThunk(
  "/deletePricingListing",
  async (data) => {
    try {
      await frontService.deleteMember(data);
      return data.orderId;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTotalAmount = createAsyncThunk(
  "/getTotalAmount",
  async (amountData) => {
    try {
      const response = await frontService.getTotalAmount(amountData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const trackCustomerOrder = createAsyncThunk(
  "/order/trackCustomerOrder",
  async (orderData) => {
    try {
      const response = await frontService.trackCustomerOrder(orderData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDownloadHistory = createAsyncThunk(
  "/order/getDownloadHistory",
  async (orderId) => {
    try {
      const response = await frontService.getDownloadHistory(orderId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const downloadPassportDocPdf = createAsyncThunk(
  "/order/downloadPassportDocPdf",
  async (orderId) => {
    try {
      const response = await frontService.downloadPassportDocPdf(orderId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const frontReducer = createSlice({
  name: "front",
  initialState: {
    loading: false,
    error: null,
    showNavbar: false,
    countryList: [],
    passportCountryList: [],
    countryCodeList: [],
    countriesList: [],
    applyNowFormData: [],
    orderIDs: null,
    membersList: [],
    amount: null,
    customerDetails: {},
    downloadHistoryList: {},
    mainResult: {},
    downloadFilePath: {},
  },
  reducers: {
    toggleNavbar: (state) => ({
      ...state,
      showNavbar: !state.showNavbar,
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getCountryListing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountryListing.fulfilled, (state, action) => {
      state.loading = false;
      state.countryList = action?.payload?.data;
    });
    builder.addCase(getCountryListing.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getPassportCountryListing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPassportCountryListing.fulfilled, (state, action) => {
      state.loading = false;
      state.passportCountryList = action?.payload?.data;
    });
    builder.addCase(getPassportCountryListing.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getCountriesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountriesList.fulfilled, (state, action) => {
      state.loading = false;
      state.countriesList = action?.payload?.data;
    });
    builder.addCase(getCountriesList.rejected, (state, action) => {
      state.loading = false;
    });

    // builder.addCase(getCountryPhoneCodeListing.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(getCountryPhoneCodeListing.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.countryCodeList = action?.payload?.data;
    // });
    // builder.addCase(getCountryPhoneCodeListing.rejected, (state, action) => {
    //   state.loading = false;
    // });
    builder.addCase(customerApplicationForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customerApplicationForm.fulfilled, (state, action) => {
      state.loading = false;
      state.applyNowFormData = action?.payload?.data;
      state.orderIDs = action?.payload?.orderId;
    });
    builder.addCase(customerApplicationForm.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(customerApplicationPreview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customerApplicationPreview.fulfilled, (state, action) => {
      state.loading = false;
      state.membersList = action?.payload?.data;
    });
    builder.addCase(customerApplicationPreview.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMember.fulfilled, (state, action) => {
      state.loading = false;
      state.applyNowFormData = state.applyNowFormData.filter(
        (item) => item?.id !== action?.payload
      );
    });
    builder.addCase(deleteMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getTotalAmount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTotalAmount.fulfilled, (state, action) => {
      state.loading = false;
      state.amount = action?.payload?.amount;
      state.customerDetails = action?.payload?.data;
    });
    builder.addCase(getTotalAmount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getDownloadHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDownloadHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.downloadHistoryList = action?.payload?.data;
      state.mainResult = action?.payload?.mainResult;
      state.downloadFilePath = action?.payload?.path;
    });
    builder.addCase(getDownloadHistory.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { toggleNavbar } = frontReducer.actions;

export default frontReducer.reducer;
