import ApiService from "./ApiService";

export default class paymentService {
  static customerApplicationPreviewSubmit = (data) =>
    ApiService.post("/front/customer-application-preview-submit", data);

  static processAuthorizeNetPay = (data) =>
    ApiService.post("/front/process-payment-authorize-net-pay", data);

  static thankyouPageInformation = (data) =>
    ApiService.post("/front/payment-success", data);

  static sendContactMail = (data) =>
    ApiService.post("/front/send-contact-mail", data);
}
