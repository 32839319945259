import styled from "styled-components";

export const SectionStyled = styled.section.attrs({
  className: "home content",
})`
  margin: 0 0 0 326px;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 9;
  position: relative;
  border-radius: 0.55rem;
`;

export const ContainerFluid = styled.div.attrs({
  className: "container-fluid",
})``;

export const BlockHeader = styled.div.attrs({ className: "block-header" })`
  padding: 10px 0;
  color: #616161;

  & li {
    list-style: none;
  }
`;

export const StyledBody = styled.div`
  padding: 10px 0;
  padding-left: 10px;

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    display: initial;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 20%;
    display: inline-block;

    // @media only screen and (max-width: 1474px) {
    //   flex: 0 0 auto;
    //   width: 25%;
    //   display: inline-block;
    // }

    // @media only screen and (max-width: 1074px) {
    //   flex: 0 0 auto;
    //   width: 33%;
    //   display: inline-block;
    // }

    @media only screen and (max-width: 991px) {
      flex: 0 0 auto;
      width: 50%;
      display: inline-block;
    }

    @media only screen and (max-width: 460px) {
      display: block;
      width: auto;
    }
  }
`;

export const StyledCard = styled.div.attrs({
  className: "row clearfix main-folder-section",
})``;

export const Card = styled.div.attrs({
  className: "col-lg-2 col-md-6 top-folder",
})`
  & a {
    color: #313740;
    text-decoration: none;
    background-color: transparent;
  }

  & h3 {
    color: #313740;
  }
`;

export const Wrapper = styled.div`
display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
    gap: 16rem;

    & ul{
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.25rem;
    }

    & li{
        padding-left: 0.5rem;
        list-style:none;
        display: contents;

    }
`;

export const StyledInput = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;

  & input {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #e3e3e3;
    border-radius: 30px;
    color: #2c2c2c;
    line-height: normal;
    font-size: 0.93em;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 3rem;
    padding-top: 5px;
    width: 69rem;
  }
`;

export const CardDiv = styled.div`
  transition: 0.5s;
  border: 0;
  position: relative;
  width: 100%;
  background: transparent;
  border-radius: 0.55rem;
  text-align: center !important;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #162331;
  background-clip: border-box;
  border-radius: 0.25rem;

  & p {
    width: 50px;
    height: 50px;
    padding: 7px;
    border-radius: 8px;
    position: absolute;
    left: 12px;
    top: 50%;
    margin-top: -25px;
    margin-bottom: 0;
    box-shadow: 0 0 18px rgb(0 0 0 / 8%);
    text-align: center;
  }

  & span {
    font-size: 13px;
    font-weight: 600px;
  }

  & img {
    height: -webkit-fill-available;
  }
`;

export const StyledMain = styled.div`
  margin-top: 3rem;
`;
